// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-clinic-tour-js": () => import("./../../../src/pages/clinic-tour.js" /* webpackChunkName: "component---src-pages-clinic-tour-js" */),
  "component---src-pages-example-js": () => import("./../../../src/pages/example.js" /* webpackChunkName: "component---src-pages-example-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-toc-js": () => import("./../../../src/pages/toc.js" /* webpackChunkName: "component---src-pages-toc-js" */),
  "component---src-templates-bodies-with-penis-js": () => import("./../../../src/templates/bodies-with-penis.js" /* webpackChunkName: "component---src-templates-bodies-with-penis-js" */),
  "component---src-templates-bodies-with-vulva-js": () => import("./../../../src/templates/bodies-with-vulva.js" /* webpackChunkName: "component---src-templates-bodies-with-vulva-js" */),
  "component---src-templates-detail-js": () => import("./../../../src/templates/detail.js" /* webpackChunkName: "component---src-templates-detail-js" */),
  "component---src-templates-method-js": () => import("./../../../src/templates/method.js" /* webpackChunkName: "component---src-templates-method-js" */),
  "component---src-templates-section-js": () => import("./../../../src/templates/section.js" /* webpackChunkName: "component---src-templates-section-js" */),
  "component---src-templates-sti-js": () => import("./../../../src/templates/sti.js" /* webpackChunkName: "component---src-templates-sti-js" */),
  "component---src-templates-topic-js": () => import("./../../../src/templates/topic.js" /* webpackChunkName: "component---src-templates-topic-js" */)
}

